import React, { useEffect, useContext, useState } from "react"
import Iframe from "../components/Iframe"
import { useParams } from "react-router-dom"
import StateContext from "../StateContext"
import ItemMenu from "../components/ItemMenu"
import Controls from "../components/Controls"

function VirtualTour() {
  const appState = useContext(StateContext)
  const { phase, id } = useParams()

  //get the items data from the menu
  const singleItem = appState.devMenuItems.virtualTour
  const multipleItems = appState.devMenuItems.virtualTours

  const [currentItem, setCurrentItem] = useState(false)

  function getCurrentItem(itemId, singleItem, multipleItems) {
    let currentItem = null

    if (multipleItems && multipleItems.length > 0) {
      if (itemId) {
        let foundItem = multipleItems.find((el) => el.slug === itemId)
        if (foundItem) {
          currentItem = foundItem
        } else {
          currentItem = multipleItems[0] //default to first
        }
      } else {
        currentItem = multipleItems[0] //default to first
      }
    } else if (singleItem) {
      currentItem = { url: singleItem, slug: "current", title: "Current" }
    }

    return currentItem
  }

  useEffect(() => {
    setCurrentItem(getCurrentItem(phase, singleItem, multipleItems))
  }, [phase, singleItem, multipleItems])

  //update the title
  let devName = appState.devName
  useEffect(() => {
    let itemTitle = currentItem ? `- ${currentItem.title}` : ""

    document.title = `Exterior Tour ${itemTitle} | ${devName}`
    window.scrollTo(0, 0)
  }, [currentItem])

  return (
    <>
      {currentItem ? (
        <Iframe
          src={
            !id ? currentItem.url : `${currentItem.url}?startscene=pano${id}`
          }
        ></Iframe>
      ) : null}

      <div className="sub-menu">
        <div className="container">
          {currentItem && multipleItems && multipleItems.length > 1 ? (
            <ItemMenu
              items={multipleItems}
              currentItem={currentItem.slug}
              route="exterior-tour"
              label="Exterior Tours"
            />
          ) : null}
        </div>
      </div>
      <Controls />
    </>
  )
}

export default VirtualTour
