import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import BackButton from "../components/BackButton"
import IndexButton from "../components/IndexButton"
import RefineMenu from "../components/RefineMenu"
import Menu from "../components/Menu"

function Controls(props) {

  const history = useHistory()
  const location = useLocation()

  function goBack() {
    history.goBack()
  }

  
  return (
    <>
      {props.showRefine ? <RefineMenu /> : null}
      {props.hideBackButton ? null : <>{location.pathname !== "/" && <BackButton goBack={goBack} />}</>}
      <Menu />
    </>
  )
}

export default Controls
