import React, { useState, useContext, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import Loading from "./Loading"
import Carousel from "react-bootstrap/Carousel"

import { dynamicSort } from "./Helpers"

import { ReactComponent as CloseIcon } from "../icons/times-light.svg"

import PlotDetails from "./PlotDetails"

import Badge from "react-bootstrap/Badge"
function Popover() {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  //check for route change and close popover
  // const location = useLocation()
  // React.useEffect(() => {
  //   appDispatch({ type: "hidePopover" })
  //   console.log("location change")
  // }, [location])

  const history = useHistory()
  const goToRoute = (location) => history.push(location)

  //add body class to lock scroll when popover is open
  React.useEffect(() => {
    if (appState.isPopoverOpen === true) {
      disableBodyScroll(document.querySelector(".off-screen-popover-content"))
    } else {
      enableBodyScroll(document.querySelector(".off-screen-popover-content"))
      // clearAllBodyScrollLocks()
    }
  }, [appState.isPopoverOpen])

  function closePopover() {
    appDispatch({ type: "hidePopover" })
  }

  function visitLink(location) {
    appDispatch({ type: "hidePopover" })
    enableBodyScroll(document.querySelector(".off-screen-popover-content"))
    goToRoute(location)
  }

  // function openPopover() {
  //   appDispatch({ type: "showPopover" })
  // }

  //plot data
  const currentPlotID = parseInt(appState.popoverContent)
  const [currentPlotData, setCurrentPlotData] = useState()
  const [currentPlotStatus, setCurrentPlotStatus] = useState()

  useEffect(() => {
    appState.plotsData.filter((el) => {
      if (el.id === currentPlotID) {
        //order images
        let sortedImages = el.plotType.assets.slice().sort(dynamicSort("order"))
        el = { ...el, sortedImages }

        //order dims
        let sortedDims = el.plotType.dimensions
          .slice()
          .sort(dynamicSort("orderValue"))
        el = { ...el, sortedDims }

        setCurrentPlotData(el)

        let status = appState.plotStatuses.filter((status) => {
          if (status.id === el.plotStatusId) {
            return el
          }
          return null
        })[0]

        setCurrentPlotStatus(status)
      }
      return null
    })
  }, [appState.plotStatuses, appState.plotsData, currentPlotID])

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (appState.isPopoverOpen) {
            closePopover()
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, appState.isPopoverOpen])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  //Update light server
  React.useEffect(() => {
    if (appState.isPopoverOpen === true) {
      if (currentPlotData) {
        appDispatch({
          type: "setCurrentLight",
          data: { ID: currentPlotData.lightId, status: "on" }
        })
      }
    } else {
      appDispatch({
        type: "turnOffCurrentLight"
      })
    }
  }, [appState.isPopoverOpen, currentPlotData])

  return (
    <div
      className={
        "off-screen-popover " +
        (appState.isPopoverOpen ? "off-screen-popover--is-visible" : "")
      }
    >
      <div className="off-screen-popover-content" ref={wrapperRef}>
        {currentPlotData ? (
          <div className="row mt-3">
            <div className="col-md-4">
              <h2 className="plot-name">Plot {currentPlotData.plotNumber}</h2>
              <h4 className="house-name">{currentPlotData.name}</h4>

              <PlotDetails item={currentPlotData} status={currentPlotStatus} />
              {/* <p className="house-description">{currentPlotData.description}</p> */}
              <button
                className="btn btn-primary mb-3 mt-3"
                onClick={() => visitLink(`/plots/${currentPlotData.id}`)}
              >
                View Home
              </button>
            </div>
            <div className="col-md-8">
              <div className="carousel-wrap">
                <div className="overlay-badge">
                  <Badge
                    pill
                    variant="light"
                    className="mb-2"
                    style={{ color: currentPlotStatus.color }}
                  >
                    {currentPlotStatus.name}
                  </Badge>{" "}
                </div>
                <Carousel interval={null} controls={false}>
                  {currentPlotData.sortedImages.map((img) => {
                    return (
                      <Carousel.Item key={img.assetId}>
                        <img
                          className="d-block w-100"
                          src={img.fileUrl}
                          alt=""
                        />
                        {/* <Carousel.Caption>
                      <p>{img.name}</p>
                    </Carousel.Caption> */}
                      </Carousel.Item>
                    )
                  })}
                </Carousel>
              </div>

              <p className="small mt-5">
                {currentPlotData.plotType?.disclaimerText}
              </p>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>

      <div className="close-button-wrap" onClick={closePopover}>
        <div className="close-button">
          <CloseIcon />
        </div>
      </div>
    </div>
  )
}

export default Popover
