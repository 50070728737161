import React from "react"
import Page from "../components/Page"
import { Link } from "react-router-dom"
import Controls from "../components/Controls"

function NotFound() {
  return (
    <Page title="Not Found">
      <div className="text-center">
        <h2 className="page-title">Whoops, we cannot find that page.</h2>
        <p className="lead text-muted">
          You can always visit the <Link to="/">homepage</Link>
        </p>
      </div>
      <Controls />
    </Page>
  )
}

export default NotFound
