import React, { useState, useContext } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import Axios from "axios"

function LoginForm(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  //login
  const [username, setUsername] = useState()

  function handleLogout() {
    // console.log("logout")
    appDispatch({ type: "logout" })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      const response = await Axios.get(`/v2/GetCustomerFavourites/${username}`,
        {
          headers: { "Registration-Key": appState.key }
        }
      )
      // console.log(response.data)
      // we have a user
      appDispatch({ type: "login", data: response.data })

      //add remote plots to local
      appDispatch({
        type: "syncFavPlots",
        data: response.data.customerFavoritePlotIds
      })
    } catch (error) {
      if (error.response.data === "Customer not found.") {
        // console.log("user not found")
        //add new user
        addUser()
      } else {
        // console.log("There was a problem.")
      }
    }
  }

  async function addUser() {
    try {
      const response = await Axios.post("/v2/CreateCustomer/", {
        name: "", //blank do we need?
        surname: "",
        email: username,
      }, {
        headers: { "Registration-Key": appState.key  }
      })
      // console.log(response.data)

      appDispatch({ type: "login", data: response.data })

      // push local favs to remote
      appDispatch({
        type: "updateRemoteFavPlots",
        data: appState.favPlots
      })
    } catch (error) {
      if (error.response.data === "Customer not found.") {
        // console.log("user not found")
      } else {
        // console.log("There was a problem.")
      }
    }
  }

  return (
    <div className="login">
      <h2>Your Account</h2>

      {!appState.loggedIn ? (
        <>
          <p>Keep your favourites within your account.</p>

          <form onSubmit={handleSubmit} className="mb-0 pt-2 pt-md-0">
            <div className="row align-items-center">
              <div className="col-md mr-0 pr-md-0 mb-3 mb-md-0">
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  name="username"
                  className="form-control form-control-sm input-dark"
                  type="email"
                  placeholder="Email Address"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-auto">
                <button className="btn btn-success btn-sm">Sign In</button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <div>
          Logged in as: <strong>{appState.user.email} </strong>
          <p>
            <button className="btn btn-secondary mt-2" onClick={handleLogout}>
              Logout
            </button>
          </p>
        </div>
      )}
    </div>
  )
}

export default LoginForm
