import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import StateContext from "../StateContext";
import SiteplanSpin from "../pages/SiteplanSpin";
import SiteplanTwelveFrames from "../pages/SiteplanTwelveFrames";

function SiteplanSwitcher() {
  /* if the spinConfig contains "twelveFrameMode" :true then display the 12 frame version of the spin
    if the spinConfig does not contain the "twelveFrameMode" then display the 360 spin [rich 16052022] */

  let { id } = useParams();
  const appState = useContext(StateContext);
  //const [currentId, setCurrentId] = useState(false);
  const [view, setView] = useState(null);

  const { globalConfigData, spinConfigData } = appState;

  const currentId = id == 0 ? globalConfigData?.spins[0].id : id;

  //const [currentId, setCurrentId] = useState(id === 0 ? test : id);

  //console.log("test" + currentId);

  /*
  useEffect(() => {
    if (globalConfigData) {
      if (globalConfigData.spins[0] != "") {
        if (id == 0) {
          setCurrentId(globalConfigData.spins[0].id);
        } else {
          setCurrentId(id);
        }
      }
    }
  }, [globalConfigData]);*/

  useEffect(() => {
    if (currentId && spinConfigData.length > 0) {
      spinConfigData.every(function (arrayItem) {
        if (arrayItem.spinId === currentId) {
          //console.log("arrayItem.spinConfigData ", arrayItem.spinConfigData);
          if (arrayItem.spinConfigData.hasOwnProperty("twelveFrameMode")) {
            if (arrayItem.spinConfigData.twelveFrameMode) {
              setView(<SiteplanTwelveFrames siteId={currentId} />);
            } else {
              setView(<SiteplanSpin siteId={currentId} />);
            }
          } else {
            setView(<SiteplanSpin siteId={currentId} />);
          }
          return false;
        }
        return true;
      });
    }
  }, [spinConfigData, currentId, id]);

  return view;
}

export default SiteplanSwitcher;
