import React from "react"
import { priceFormatter } from "./Helpers"

function CustomFields(props) {
  const { item } = props
  if (item.customFields && item.customFields.length >= 1) {
    return (
      <div className="custom-fields">
        {item.customFields.map((field) => {
          let val = ""
          if (field.displaysCurrency) {
            val = priceFormatter(field.fieldValue)
          } else {
            val = field.fieldValue
          }
          return (
            <div className="field" key={field.id}>
              {field.displayName} - {val}
            </div>
          )
        })}
      </div>
    )
  } else {
    return null
  }
}

export default CustomFields
