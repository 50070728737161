import React from "react"

import { scorePositionInBand, EPCColors } from "../components/Helpers"

import EPCBadge from "./EPCBadge"
import EPCScore from "./EPCScore"
import EPCCompareButton from "./EPCCompareButton"


function EPCGauge({ epc, plotType, userHome, setShowEpcCompare }) {
  const [letter] = epc.sapCurrent.split(" ")

  return (
    <div className="epc-gauge">
      <div className="epc-gauge__badge">
        <EPCBadge type="Eco Electric" size="lg" />
      </div>
      <div className="epc-gauge__intro">
        <p>
          This Eco Electric home features a super efficient air source heat pump
          for heating and hot water.
        </p>

       <EPCCompareButton onClick={() => setShowEpcCompare(true)} type='short' />

      </div>
      <div className="epc-gauge__bands">
        {Object.keys(EPCColors).map(
          (band) =>
            band !== "default" && (
              <div key={band} className="epc-gauge__band">
                <div
                  className="epc-gauge__indicator"
                  style={{ background: EPCColors[band] }}
                />
                {band === letter && (
                  <div
                    className="epc-gauge__content"
                    style={{ top: `${scorePositionInBand(epc.sapCurrent)}%` }}
                  >
                    <EPCScore score={epc.sapCurrent} size="lg" />
                    <span className="epc-gauge__plotType">{plotType}</span>
                  </div>
                )}

                {!/^[A-Z]$/.test(letter) && band === "B" && (
                  <div className="epc-gauge__content" style={{ top: `50%` }}>
                    <EPCScore score="B 84" size="lg" />
                    <span className="epc-gauge__plotType">
                      Average Redrow home
                    </span>
                  </div>
                )}

                {!userHome ? (
                  <>
                    {band === "D" && (
                      <div
                        className="epc-gauge__content"
                        style={{ top: `${scorePositionInBand("D 56")}%` }}
                      >
                        <EPCScore score="D 56" />
                        <span className="epc-gauge__houseType">
                          Average 70&apos;s home
                        </span>
                      </div>
                    )}

                    {band === "E" && (
                      <div
                        className="epc-gauge__content"
                        style={{ top: `${scorePositionInBand("E 43")}%` }}
                      >
                        <EPCScore score="E 43" />
                        <span className="epc-gauge__houseType">
                          Average Victorian home
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {band === userHome["current-energy-rating"] && (
                      <div
                        className="epc-gauge__content"
                        style={{
                          top: `${scorePositionInBand(
                            `${userHome["current-energy-rating"]} ${userHome["current-energy-efficiency"]}`
                          )}%`
                        }}
                      >
                        <EPCScore
                          score={`${userHome["current-energy-rating"]} ${userHome["current-energy-efficiency"]}`}
                        />
                        <span className="epc-gauge__houseType">
                          Your current home
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default EPCGauge
