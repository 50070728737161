import React, { useContext } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import { ReactComponent as CloseIcon } from "../icons/times-light.svg"

function FilterStatusBar(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  //unset filter
  const unsetFilter = (filterIndex) => (e) => {
    // let newFilters = [...filters] // copying the old
    let newFilters = JSON.parse(JSON.stringify(appState.filters)) // copying the old

    if (appState.filters[filterIndex].type === "range") {
      newFilters[filterIndex].selectedValues = newFilters[filterIndex].values
      //////
      // setRangeLabels(newFilters[filterIndex].values)
    } else if (appState.filters[filterIndex].type === "checkbox") {
      //set all values to false
      newFilters[filterIndex].values.map(function (value) {
        value.isChecked = false
        return value
      })
    }

    //remove active state
    newFilters[filterIndex].active = false

    // setFilters(newFilters)
    appDispatch({
      type: "setFilters",
      data: newFilters
    })
  }

  return (
    <div className="filter-status-bar">
      {appState.filters && appState.filters.some((e) => e.active) ? (
        <h5>Filtered by</h5>
      ) : null}
      {appState.filters && appState.filters.length
        ? appState.filters.map((filter, filterIndex) => {
            if (filter.active) {
              return (
                <div className="filter-status-item" key={filterIndex}>
                  <span>{filter.displayName}</span>
                  <button onClick={unsetFilter(filterIndex)}>
                    <div className="svg-icon-inline baseline">
                      <CloseIcon />
                    </div>
                  </button>
                </div>
              )
            } else {
              return null
            }
          })
        : null}
    </div>
  )
}

export default FilterStatusBar