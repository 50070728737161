import React from "react"

function Container(props) {
  return (
    <div
      className={" " + (props.fluid ? "container-fluid" : "container") + (props.spaceBelow ? " space-below" : "")}
    >
      {props.children}
    </div>
  )
}

export default Container
