import React from "react"
import { CookiesProvider, useCookies } from "react-cookie"
import KeyboardKey from "./KeyboardKey"
import { ReactComponent as BackspaceIcon } from "../icons/backspace.svg"

function Keyboard({ text, setText, search }) {
  const [cookies] = useCookies(["inMarketingSuite"])

  const characters = [
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
    ["Z", "X", "C", "V", "B", "N", "M"]
  ]

  const addCharacter = (character) => {
    setText(text.concat(character))
  }

  const removeCharacter = () => {
    setText(text.slice(0, -1))
  }

  const keyRows = characters.map((row, i) => (
    <div className="keyboard__row" key={i}>
      {row.map((character, j) => (
        <KeyboardKey
          key={j}
          character={character}
          onTouch={() => addCharacter(character)}
          // disabled={disabled}
        />
      ))}
    </div>
  ))

  if (keyRows.length) {
    const lastRow = keyRows.pop()
    keyRows.push(
      <div className="keyboard__row" key="lastrow">
        <KeyboardKey
          character={<BackspaceIcon />}
          special
          onTouch={() => removeCharacter()}
        />
        {lastRow}
        <KeyboardKey
          character={"Search"}
          special
          light
          onTouch={() => text && search(text)}
          disabled={!text}
        />
      </div>
    )
  }

  return (
    <CookiesProvider>
      {cookies.inMarketingSuite && <div className="keyboard">{keyRows}</div>}
    </CookiesProvider>
  )
}

export default Keyboard
