import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Page from "../components/Page"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"
import PlotCard from "../components/PlotCard"
import FilterStatusBar from "../components/FilterStatusBar"
import Controls from "../components/Controls"

function Plots() {
  //app state
  const appState = useContext(StateContext)

  const newHomes = [...appState.filteredHomes]
  newHomes.sort()

  return (
    <Page title="Our Homes" container={true} fluid={false} spaceBelow>
      <div className="plots-header">
        <h2 className="page-title">
          Find<span className="bold"> Your New Home</span>
        </h2>
        <div>
        </div>
      </div>

      <div className="top-bar row">
        <div className="col-md-12">
          <FilterStatusBar />
        </div>
      </div>

      <div className="plots-list">
        <div className="plots-grid row" key="plots-grid">
          {appState.filteredHomes.length >= 1 ? (
            [...appState.filteredHomes].sort((a,b) => parseFloat(a.plotNumber) - parseFloat(b.plotNumber)).map((item) => {
              return <PlotCard item={item} key={item.id} />
            })
          ) : (
            <div className="col-md-12 plot-card item" key={0}>
              <Card className="mb-4">
                <Card.Body>No available plots.</Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>
      <Controls showRefine />
    </Page>
  )
}

export default Plots
