import React, {useEffect} from "react"

import useCompCalc, { format } from "../hooks/useCompCalc"

import { ReactComponent as Bolt } from "../icons/bolt.svg"
import { ReactComponent as Co2 } from "../icons/co2.svg"
import { ReactComponent as DownArrow } from "../icons/downArrow.svg"

import { ReactComponent as PiggyBank } from "../icons/piggyBank.svg"

import { ReactComponent as TeaCup } from "../icons/epcTeaCup.svg"
import { ReactComponent as Bulb } from "../icons/epcBulb.svg"
import { ReactComponent as Car } from "../icons/epcCar.svg"
import { ReactComponent as Trees } from "../icons/epcTrees.svg"
import { ReactComponent as Ballons } from "../icons/epcBallons.svg"
import { ReactComponent as Bricks } from "../icons/epcBricks.svg"



function EPCHomeCompare({ epc, userHome, plotType }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //users home
  const userKgPerYear = (
    parseFloat(userHome["co2-emissions-current"]) * 1000
  ).toFixed(1)
  const userEnergyPerYear =
    parseFloat(userHome["energy-consumption-current"]) *
    parseFloat(userHome["total-floor-area"]) //kWh/year

  //new home
  const newHomeKgPerYear = parseFloat(epc.cO2TotalKGYear).toFixed(1)
  const newHomeEnergyPerYear = parseFloat(epc.primaryEnergyKWHYear)

  const energyDiff = userEnergyPerYear - newHomeEnergyPerYear
  const co2Diff = userKgPerYear - newHomeKgPerYear
  const savings = useCompCalc(co2Diff, energyDiff)

  return (
    <div className="home-compare">
      <div className="home-compare__intro">
        <div className="home-compare__intro-text">
          <p>
            Moving from your home to <b>{plotType}</b>
          </p>
          <h3>{savings.coins}</h3>
        </div>
        <div className="home-compare__intro-icon">
          <PiggyBank />
        </div>
      </div>

      <div className="home-compare__stats">
        <div className="home-compare__energy">
          <div className="home-compare__type">
            <div className="home-compare__type-icon">
              <Bolt />
            </div>
            <div>
              <span className="home-compare__trend-icon">
                <DownArrow />
              </span>
              <b>{format(energyDiff, 0)}</b> kWh
            </div>
          </div>

          <div className="home-compare__stat">
            <div className="home-compare__stat-icon">
              <TeaCup />
            </div>
            {savings.kettle}
          </div>

          <div className="home-compare__stat">
            <div className="home-compare__stat-icon">
              <Bulb />
            </div>
            {savings.lightbulbs}
          </div>

          <div className="home-compare__stat">
            <div className="home-compare__stat-icon">
              <Car />
            </div>
            {savings.car}
          </div>
        </div>

        <div className="home-compare__co2">
          <div className="home-compare__type">
            <div className="home-compare__type-icon">
              <Co2 />
            </div>
            <div>
              <span className="home-compare__trend-icon">
                <DownArrow />
              </span>
              <b>{format(co2Diff, 0)}</b> kg
            </div>
          </div>

          <div className="home-compare__stat">
            <div className="home-compare__stat-icon">
              <Trees />
            </div>
            {savings.trees}
          </div>

          <div className="home-compare__stat">
            <div className="home-compare__stat-icon">
              <Ballons />
            </div>
            {savings.balloons}
          </div>

          <div className="home-compare__stat">
            <div className="home-compare__stat-icon">
              <Bricks />
            </div>
            {savings.bricks}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EPCHomeCompare
