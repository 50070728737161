import React, { useContext, useEffect, useState } from "react";
import StateContext from "../StateContext";
import StatusKeyItem from "../components/StatusKeyItem";

function StatusKey(props) {
  const appState = useContext(StateContext);
  const [plotStatusArray, setPlotStatusArray] = useState([]);

  useEffect(() => {
    if (!appState.plotStatuses) return;

    appState.plotStatuses.forEach(function (aPlotStatus) {
      if (aPlotStatus.name !== "Hidden") {
        setPlotStatusArray((obj) => [
          ...obj,
          { statusText: aPlotStatus.name, statusColor: aPlotStatus.color },
        ]);
      }
    });
  }, [appState.plotStatuses]);

  return (
    <div className="status-container">
      <div className="status-container__wrap">
        {plotStatusArray.map((thisStatusItem) => (
          <div className="status-container__item" key={thisStatusItem.statusText}>
            <StatusKeyItem
              color={thisStatusItem.statusColor}
              text={thisStatusItem.statusText}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default StatusKey;
