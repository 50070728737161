import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Card from "react-bootstrap/Card"
import Badge from "react-bootstrap/Badge"
import PlotDetails from "./PlotDetails"
import StateContext from "../StateContext"

function PlotCard(props) {
  const appState = useContext(StateContext)
  const { item } = props

  //get status
  let status = appState.plotStatuses.filter((el) => {
    if (el.id === item.plotStatusId) {
      return el
    }
    return null
  })[0]

  return (
    <div className="col-md-4 col-lg-3 plot-card item" key={item.id}>
      <Card className="mb-4">
        <Card.Header>
          <strong className="mr-2">Plot {item.plotNumber}</strong>{" "}
          <Badge pill variant="light" style={{ color: status.color }}>
            {status.name}
          </Badge>{" "}
        </Card.Header>
        <Card.Body>
          <h4 className="house-name">{item.name}</h4>
          <PlotDetails item={item} status={status} />
          <Link to={`/plots/${item.id}`} className="btn btn-primary">
            View Home
          </Link>
        </Card.Body>
      </Card>
    </div>
  )
}

export default PlotCard
