import React, { useRef } from "react";

function RoadMarker(props) {
  const { posY, posX, posZ, name, scale } = props;
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
  const markerSize = clamp(0.8 / scale, 0.25, 1.0);
  const thisRef = useRef(null);

  const divStyle = {
    top: `calc(${posY}% - ${thisRef.current?.offsetHeight / 2}px)`,
    left: `calc(${posX}% - ${thisRef.current?.offsetWidth / 2}px)`,
    zIndex: posZ,
    transform: `scale(${markerSize})`,
  };

  return (
    <div ref={thisRef} className="roadMarker" style={divStyle}>
      {name}
    </div>
  );
}

export default RoadMarker;
