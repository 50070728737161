import React from "react"
import Indicator from "../icons/Indicator"
import { scoreToColor } from "../components/Helpers"

function EPCScore({ score }) {
  const [letter, number] = score.split(" ")

  return (
    <div className="epc-score">
      <Indicator fill={scoreToColor(score)} />
      <div className="epc-score__content">
        <b>{letter}</b> {` `} <span>{number}</span>
      </div>
    </div>
  )
}

export default EPCScore
