import React, { useEffect, useContext } from "react"
import Iframe from "../components/Iframe"
import { useParams } from "react-router-dom"
import StateContext from "../StateContext"
import Controls from "../components/Controls"

function VirtualTour() {
  const appState = useContext(StateContext)
  const id = useParams().id

  const typeTours = appState.devMenuItems.typeTours
  const currentTypeTour = id ? typeTours.find((el) => el.slug === id) : null

  let devName = appState.devName

  useEffect(() => {
    document.title = `Interior Tour | ${devName}`
    window.scrollTo(0, 0)
  }, [devName])

  return (
    <>
      <Iframe src={id ? currentTypeTour.url : typeTours[0].url}></Iframe>
      <Controls />
    </>
  )
}

export default VirtualTour
