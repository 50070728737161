import React, { useState, useContext, useMemo, useRef, useEffect } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"
import useWebSocket, { ReadyState } from "react-use-websocket"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"

import { useLocation, matchPath } from "react-router-dom"

function LightServer(props) {
  const debug = false
  const { lightServerAddress } = props

  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const [message, setMessage] = useState()

  const messageHistory = useRef([])

  const { sendMessage, lastMessage, readyState } =
    useWebSocket(lightServerAddress)

  messageHistory.current = useMemo(
    () => messageHistory.current.concat(lastMessage),
    [lastMessage]
  )

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated"
  }[readyState]

  //form
  function handleMessageChange(event) {
    setMessage(event.target.value)
  }

  function handleSendMessage(event) {
    event.preventDefault()
    sendMessage(message)
  }

  //turn off last light on route change
  const [onPlotPage, setOnPlotPage] = useState(false)
  const location = useLocation()
  React.useEffect(() => {
    if (onPlotPage) {
      //location has changed and we were on a plot page so turn off the current light
      setOnPlotPage(false)
      appDispatch({
        type: "turnOffCurrentLight"
      })
    }
    //check if we are on a plot page
    const match = matchPath(location.pathname, {
      path: "/plots/:id",
      exact: false,
      strict: false
    })
    if (match) {
      setOnPlotPage(true)
    }
  }, [location])

  //send message on state change
  useEffect(() => {
    let ID = appState.currentLight.ID
    let status = appState.currentLight.status
    if (status === "on" && ID) {
      sendMessage(`{"Command": 7, "LightID": ${ID}}`)
    } else if (status === "off" && ID) {
      sendMessage(`{"Command": 1, "LightID": ${ID}}`)
    }
  }, [appState.currentLight, sendMessage])

  if(debug){
    return (
      <div className="p-2" style={{ backgroundColor: "grey", color: "white" }}>
        <span>The WebSocket is currently {connectionStatus}.</span>
  
        <span>
          {" "}
          Current Light ID:{" "}
          {appState.currentLight ? appState.currentLight.ID : "none"} Current
          Light Status:{" "}
          {appState.currentLight ? appState.currentLight.status : "none"}
        </span>
  
        <Form>
          <Form.Row className="align-items-center">
            <Col xs="auto">
              <Form.Label htmlFor="inlineFormInput" srOnly>
                Message
              </Form.Label>
              <Form.Control
                className="mb-2"
                id="inlineFormInput"
                placeholder="Message..."
                onChange={handleMessageChange}
              />
            </Col>
            <Col xs="auto">
              <button
                type="submit"
                className="mb-2"
                onClick={handleSendMessage}
                disabled={readyState !== ReadyState.OPEN}
              >
                Send
              </button>
            </Col>
          </Form.Row>
        </Form>
  
        {lastMessage ? (
          <span>Last received message: {lastMessage.data}</span>
        ) : null}
  
        {/* {console.log(messageHistory)} */}
      </div>
    )

  } else {
    return null
  }


}

export default LightServer
