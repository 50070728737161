import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import StateContext from "../StateContext"
import { SizeMe } from "react-sizeme"
import ItemMenu from "../components/ItemMenu"
import Controls from "../components/Controls"

import PageWrap from "../components/Page"
import { Document, Page, pdfjs } from "react-pdf"
import Loading from "../components/Loading"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function MasterPlan() {
  const appState = useContext(StateContext)

  const url = appState.devMenuItems.masterPlan
  const items = appState.devMenuItems.masterPlans

  const id = useParams().id
  const currentItem = id ? items.find((el) => el.slug === id) : null

  //check for single or multiple items
  let currentURL = ""
  if (url) {
    currentURL = url
  } else if (items) {
    currentURL = id ? currentItem.url : items[0].url
  }

  const MasterPlan = function (props) {
    let { url } = props

    if (url.endsWith("jpg") || url.endsWith("png")) {
      return <MasterPlanJpg url={url} />
    } else if (url.endsWith("pdf")) {
      return <MasterPlanPdf url={url} />
    }
  }

  const MasterPlanJpg = function (props) {
    let { url } = props

    let devName = appState.devName
    useEffect(() => {
      document.title = `Master Plan | ${devName}`
      window.scrollTo(0, 0)
    }, [devName])

    return (
      <>
        <SizeMe
          monitorHeight={true}
          refreshRate={128}
          refreshMode={"debounce"}
          render={({ size }) => (
            <div className="resize-container">
              <img
                className="resize-image"
                style={{ maxWidth: size.width, maxHeight: size.height }}
                src={url}
                alt=""
              />
            </div>
          )}
        />
        <SubMenu />
      </>
    )
  }

  const MasterPlanPdf = function (props) {
    let { url } = props

    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages)
    }

    function prev() {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1)
      }
    }

    function next() {
      if (pageNumber < numPages) {
        setPageNumber(pageNumber + 1)
      }
    }

    return (
      <PageWrap title="Master Plan" fluid={false} container={false}>
        <SizeMe
          monitorHeight={true}
          refreshRate={128}
          refreshMode={"debounce"}
          render={({ size }) => (
            <div className="resize-container">
              <div style={{ maxWidth: size.width, maxHeight: size.height }}>
                <Document
                  file={url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={Loading}
                  renderMode={"canvas"}
                >
                  <Page
                    pageNumber={pageNumber}
                    height={size.width > size.height ? size.height - 60 : null}
                    width={size.width > size.height ? null : size.width - 50}
                    renderTextLayer={false}
                  />
                </Document>
              </div>
            </div>
          )}
        />

        {numPages > 1 ? (
          <div className="pdf-controls">
            <button
              onClick={prev}
              className="btn btn-secondary"
              disabled={pageNumber === 1 ? true : false}
            >
              Prev
            </button>
            <span className="page-status">
              {pageNumber} of {numPages}
            </span>
            <button
              onClick={next}
              className="btn btn-secondary"
              disabled={pageNumber === numPages ? true : false}
            >
              Next
            </button>
          </div>
        ) : null}

        <SubMenu high={numPages > 1 ? true : false} />
      </PageWrap>
    )
  }

  const SubMenu = function (props) {
    let { high } = props
    return (
      <div className={`sub-menu ${high ? "high" : ""}`}>
        <div className="container">
          {items ? (
            <ItemMenu
              items={items}
              currentItem={id ? currentItem.slug : items[0].slug}
              route="masterplan"
              label="Master Plans"
            />
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <>
      <MasterPlan url={currentURL} />
      <Controls />
    </>
  )
}

export default MasterPlan
