import React from "react"

import { ReactComponent as BackArrow } from "../icons/long-arrow-left-light.svg"

function Menu(props) {
  const { goBack } = props;

  return (
    <>
      <div className="back-button" onClick={goBack}>
        <BackArrow />
      </div>
    </>
  )
}

export default Menu
