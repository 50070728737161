import React from "react"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

function MapMark(props) {
  const { name, color } = props

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {name}
    </Tooltip>
  )

  return (
    <OverlayTrigger
      placement="top"
      trigger={["click"]}
      overlay={renderTooltip}
      delay={{ show: 250, hide: 400 }}
      rootClose
      rootCloseEvent={"click"}
    >
      <div
        className="marker"
        style={{ backgroundColor: color, cursor: "pointer" }}
      ></div>
    </OverlayTrigger>
  )
}

export default MapMark
