import React from "react"

import { motion, AnimatePresence } from "framer-motion"
import { Link } from "react-router-dom"

import { ReactComponent as TouchIcon } from "../icons/touch-icon.svg"

const pulseVariants = {
  initial: {
    scale: 0,
    opacity: 0.6
  },
  animate: (index) => ({
    scale: 1,
    opacity: 0,
    transition: {
      duration: 4,
      delay: index * 0.6666,
      repeat: Infinity
    }
  })
}

const shrinkGrowVariants = {
  initial: {
    scale: 1
  },
  animate: {
    scale: 1.2,
    transition: {
      type: "spring",
      damping: 10,
      mass: 0.75,
      stiffness: 100,
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse"
    }
  }
}

const PulseButton = () => {
  return (
    <div
      style={{
        height: 220,
        width: 220,
        position: "relative",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {Array(6)
        .fill(null)
        .map((_, index) => {
          return (
            <motion.div
              key={index}
              variants={pulseVariants}
              initial="initial"
              animate="animate"
              custom={index}
              style={{
                height: 300,
                width: 300,
                backgroundColor: "#C20924",
                borderRadius: 200,
                position: "absolute"
              }}
            />
          )
        })}
      <motion.div
        style={{
          height: 150,
          width: 150,
          backgroundColor: "#C20924",
          borderRadius: 100,
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#ffffff",
          boxShadow: "0px 1px 17px 0px rgba(0, 0, 0, 0.4)"
        }}
        className="box-shadow"
        variants={shrinkGrowVariants}
        initial="initial"
        animate="animate"
        whileHover={{ scale: 1.3 }}
        whileTap={{ scale: 1 }}
      >
        <Link
          to="types"
          style={{
            display: "block",
            height: 200,
            width: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            webkitTapHighlightColor: "rgba(0,0,0,0)",
            color: "#fff"
          }}
        >
          <TouchIcon />
        </Link>
      </motion.div>
    </div>
  )
}

export default PulseButton