import React from "react";

function StatusKeyItem(props) {
  const { text, color } = props;

  return (
    <>
      <div
        className="status-container__item__icon"
        style={{ backgroundColor: color }}
      />
      <div className="status-container__item__label">{text}</div>
    </>
  );
}

export default StatusKeyItem;
