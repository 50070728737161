import React, { useEffect, useContext } from "react"
import Container from "./Container"
// import Footer from "./Footer"
import StateContext from "../StateContext"

function Page(props) {
  const appState = useContext(StateContext)
  let devName = appState.devName

  useEffect(() => {
    document.title = `${props.title} | ${devName}`
    window.scrollTo(0, 0)
  }, [props.title, devName])

  return (
    <>
      {props.container && appState.devStyle ? (
        <Container
          className="container-bg"
          fluid={props.fluid}
          spaceBelow={props.spaceBelow}
          style={
            {
              // backgroundImage: `url(${appState.devStyle.images.backgroundImageUrl})`
            }
          }
        >
          {props.children}
        </Container>
      ) : (
        <div
          className="page-wrap container-bg"
          style={
            {
              // backgroundImage: `url(${appState.devStyle.images.backgroundImageUrl})`
            }
          }
        >
          {props.children}
        </div>
      )}

      {/* <div className="page-wrap">{props.children}</div> */}

      {/* <Footer /> */}
    </>
  )
}

export default Page
