import React, { useContext } from "react"
import DispatchContext from "../DispatchContext"
import { useHistory } from "react-router-dom"
import Popover from "./Popover"

function Iframe(props) {
  const { src, currentItem } = props

  const appDispatch = useContext(DispatchContext)

  //console.log("currentItem", currentItem)

  React.useEffect(() => {
    const handleMessage = (event) => {
      if (
        event.data.source === "virtual-tour" &&
        event.data.payload.event === "route"
      ) {
        //hack doing something different for siteplan only so we go to the correct phase
        if (
          currentItem &&
          currentItem.url.includes("/spin") &&
          event.data.payload.location.includes("exterior-tour")
        ) {
          let loc = event.data.payload.location.replace("/exterior-tour/", "")
          goToRoute(`/exterior-tour/${currentItem.slug}/${loc}`)
        } else {
          goToRoute(event.data.payload.location)
        }

        console.log("Event", event.data)
      } else if (
        event.data.source === "virtual-tour" &&
        event.data.payload.event === "popover"
      ) {
        appDispatch({ type: "showPopover", data: event.data.payload.location })
        console.log("Event", event.data)
      } else {
        return null
      }
    }

    window.addEventListener("message", handleMessage)

    //cleanup
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  })

  const history = useHistory()
  const goToRoute = (location) => history.push(location)

  return (
    <>
      <iframe
        className="full-iframe"
        src={src}
        width="100%"
        height="100%"
        title="Virtual Tour"
      ></iframe>
      <Popover />
    </>
  )
}

export default Iframe
