import React from "react"

function Indicator({
  width = 117.537,
  height = 59.561,
  fill = 'currentColor',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 117.537 59.561'
      preserveAspectRatio='xMinYMin slice'
      {...props}
    >
      <path
        id='Path_2960'
        data-name='Path 2960'
        d='M32.973,59.561,14.765,29.781,32.973,0H132.3V59.561Z'
        transform='translate(-14.765)'
        fill={fill}
      />
    </svg>
  );
}

export default Indicator;
