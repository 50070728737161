import React, { useContext, useState, useEffect } from "react";
import StateContext from "../StateContext";
import { DropTarget } from "react-drag-drop-container";

function VrMarker(props) {
  const appState = useContext(StateContext);
  const { posY, posX, targId, scale } = props;

  const markerSize = 2.6 / scale;
  const [border, setBorder] = useState("1px solid rgba(255, 255, 255, 0)");
  const { isVrpersonActive } = appState;

  const divStyle = {
    top: `calc(${posY}% - 6px)`,
    left: `calc(${posX}% - 6px)`,
    transform: `scale(${markerSize})`,
    transition: "all 200ms ease-in-out",
    border: `${border}`,
  };

  useEffect(() => {
    if (isVrpersonActive) {
      setBorder("1px solid rgba(255, 255, 255, 1)");
    } else {
      setBorder("1px solid rgba(255, 255, 255, 0)");
    }
  }, [isVrpersonActive, markerSize]);

  return (
    <>
      {isVrpersonActive ? (
        <DropTarget
          targetKey="vr_marker"
          dropData={targId}
          onDragEnter={() => setBorder("1px solid rgba(202, 9, 40, 1)")}
          onDragLeave={() => setBorder("1px solid rgba(255, 255, 255, 1)")}
        >
          <div className="vrMarker" style={divStyle} />
        </DropTarget>
      ) : null}
    </>
  );
}

export default VrMarker;
