
import React,{useContext, useState, useEffect} from 'react'
import StateContext from "../StateContext"
import { Link } from "react-router-dom"

function ZoneMarker(props) {


//console.log('drawing zone markers')

  const appState = useContext(StateContext)
  const [divStyle, setDivStyle] = useState()
  const [enableLink, setEnableLink] = useState('')

  const newDivStyle = {
    top: props.posY + '%',
    left: props.posX + '%',
    zIndex: 2
  }

  useEffect(()=>{
    setDivStyle(newDivStyle)
  },[props.posX, props.posY])

  useEffect(()=>{
    if(appState.isPanning){
      setEnableLink(`${props.url}`)
    }else{
      setEnableLink(`${props.url}/blockplan/${props.targId}`)
    }
  },[appState.isPanning])

  return (
    <Link className="zoneMarkerBP" to={enableLink} style={divStyle}>{props.name}</Link>
    )
  }

  export default ZoneMarker
