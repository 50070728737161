import React, { useState, useEffect, useRef } from "react"
import { chain, startCase, toLower } from "lodash"

import useEPCApiData from "../hooks/useEPCApiData"

import EPCHomeCompare from "../components/EPCHomeCompare"

import Keyboard from "./Keyboard"
import Loading from "./Loading"

import { ReactComponent as SearchIcon } from "../icons/magnifying-glass-solid.svg"
import { ReactComponent as RightChevron } from "../icons/chevron-right-solid.svg"
import { ReactComponent as Cross } from "../icons/times-light.svg"

function TextInput({ text, setText, search, isSearching }) {
  async function handleSubmit(e) {
    e.preventDefault()
    search(text)
  }
  return (
    <form onSubmit={handleSubmit} className="search-form">
      <input
        onChange={(e) => setText(e.target.value)}
        name="query"
        className="search-form__text-input"
        type="text"
        placeholder="Enter Your Postcode"
        autoComplete="off"
        value={text}
      />
      <button className="search-form__search-button">
        {isSearching ? <Loading /> : <SearchIcon />}
      </button>
    </form>
  )
}

function Errors({ apiError }) {
  return (
    <>
      {apiError ? (
        <>
          {String(apiError).includes("No Results found for ") ? (
            <h3 className="error">{apiError} Please try again.</h3>
          ) : (
            <h3 className="error">
              We are having difficulty fetching the requested EPC data. Please
              try again later.
            </h3>
          )}
        </>
      ) : null}
    </>
  )
}

function SearchForm({ text, setText, search, apiError, isSearching }) {
  return (
    <>
      <TextInput
        text={text}
        setText={setText}
        search={() => search(text)}
        isSearching={isSearching}
      />
      <Errors apiError={apiError} />
      <Keyboard search={() => search(text)} text={text} setText={setText} />
    </>
  )
}

function AddressSelect({ homes, setUserHome, setStep }) {
  const formattedHomes = () => {
    if (!homes) return null

    homes = homes.map((home) => {
      const number = !isNaN(home.address1.split(" ")[0])
        ? Number(home.address1.split(" ")[0])
        : home.address1

      return {
        ...home,
        "address-number": number,
        address: startCase(toLower(home.address.replace(",", ""))),
        address1: startCase(toLower(home.address1.replace(",", "")))
      }
    })

    return chain(homes).sortBy("address-number").uniqBy("address1").value()
  }

  const handleHomeClick = (home) => {
    if (home["current-energy-rating"] === "A") {
      setStep(5)
      /// what to do here??
    } else {
      setUserHome(home)
    }
  }

  return (
    <div className="address-select">
      <div>
        <p> Select your home from the properties below</p>
      </div>
      {formattedHomes() &&
        formattedHomes().map((home, i) => {
          return (
            <div
              key={i}
              onClick={() => handleHomeClick(home)}
              className="address-select__address-container"
            >
              <div className="address-select__address">{home["address"]}</div>
              <RightChevron />
            </div>
          )
        })}
    </div>
  )
}

function EPCCompare({
  epc,
  plotType,
  userHome,
  setUserHome,
  setShowEpcCompare
}) {
  const [apiData, apiError, isSearching, search, apiDataReset] = useEPCApiData()
  const [text, setText] = useState("")

  useEffect(() => {
    apiError && setText("")
  }, [apiError])

  function handleClose() {
    setShowEpcCompare(false)
    setUserHome(null)
  }

  const scrollContainerRef = useRef();

  const scrollContainer = () => {
    scrollContainerRef.current.scrollTo(0, 0)
  }

  useEffect(() => {
    scrollContainer()
  }, [apiData, userHome])

  return (
    <div className="epc-compare">
      <div className="epc-compare__container" ref={scrollContainerRef}>
        <div className="epc-compare__content">
          <h2>Compare your Home</h2>

          {(!userHome && !apiData) || apiError ? (
            <SearchForm
              text={text}
              setText={setText}
              search={() => search(text)}
              apiError={apiError}
              isSearching={isSearching}
            />
          ) : null}

          {!isSearching && !apiError ? (
            <>
              {/* home list */}
              {apiData && apiData["rows"] && !userHome ? (
                <AddressSelect
                  homes={apiData["rows"]}
                  setUserHome={setUserHome}
                />
              ) : null}
              {/* compare  */}
              {userHome ? (
                <EPCHomeCompare
                  epc={epc}
                  userHome={userHome}
                  plotType={plotType}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      <div className="epc-compare__close-button" onClick={() => handleClose()}>
        <Cross />
      </div>
    </div>
  )
}

export default EPCCompare
