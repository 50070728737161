import React from "react"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

import { ReactComponent as MapMarkIcon } from "../icons/map-marker.svg"

function MapMark(props) {
  const { name } = props

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {name}
    </Tooltip>
  )

  return (
    <OverlayTrigger
      placement="top"
      trigger={["click"]}
      overlay={renderTooltip}
      delay={{ show: 250, hide: 400 }}
      rootClose
      rootCloseEvent={"click"}
    >
      <div
        className="pin bounce development-map-mark"
        style={{ cursor: "pointer" }}
      >
        <MapMarkIcon />
      </div>
    </OverlayTrigger>
  )
}

export default MapMark
