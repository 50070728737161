import { useState, useEffect } from "react"

import axios from "axios"

export default function useEPCApiData() {
  const [apiData, setApiData] = useState(null)
  const [apiError, setApiError] = useState(null)
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    if (apiError) {
      console.error(apiError)
    }
  }, [apiError])

  const search = (postcode) => {
    setApiError(null)

    if (postcode) {
      setIsSearching(true)

      const url = `https://epc.opendatacommunities.org/api/v1/domestic/search?postcode=${postcode}`

      axios
        .get(`${url}`, {
          headers: {
            Authorization:
              "Basic ZGFuZS5yaWNoQHRoaW5rYmR3LmNvLnVrOmIyODhkM2Y3MWNiMjE2NTAzYzkyZGM1ZmJlZDY3ODEwY2VlMzIwMjA=",
            Accept: "application/json"
          }
        })
        .then((response) => {
          response.data["rows"]
            ? setApiData(response.data)
            : setApiError(`No Results found for ${postcode}.`)
        })
        .catch((error) => {
          setApiError(error)
        })
        .finally(() => {
          setIsSearching(false)
        })
    } else {
      setApiError("Cannot search with an empty string.")
    }
  }

  const apiDataReset = () => {
    setApiData(null)
    setApiError(null)
    setIsSearching(false)
  }

  return [apiData, apiError, isSearching, search, apiDataReset]
}
