import React from "react"

export const format = (value, precision = 1, decimals = 0) => {
  const round = (value, precision, decimals) => {
    var multiplier = Math.pow(10, precision || 0)
    return (Math.round(value * multiplier) / multiplier).toFixed(decimals)
  }

  return round(value, precision, decimals)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export default function useCompCalc(co2Diff, energyDiff) {
  const kwhCupOfTea = 0.0375
  const kwhLightBulbYear = 876
  const kwhPerMile = 0.3571428

  const kgPerCubicMeter = 1.836
  const balloonVol = 0.0070792

  const averageBrickWeight = 3

  const co2CubicMeters = co2Diff * kgPerCubicMeter
  const balloons = co2CubicMeters / balloonVol

  const treeKgYear = 21

  const trees = co2Diff / treeKgYear

  const bricks = co2Diff / averageBrickWeight

  const cupsOfTea = energyDiff / kwhCupOfTea
  const yearsLightBulb = energyDiff / kwhLightBulbYear
  const miles = energyDiff / kwhPerMile

  const elecBalance = 0.195
  const gasBalance = 0.805
  const elecCostKwh = 0.34037
  const gasCostKwh = 0.1033

  //https://www.britishgas.co.uk/energy/guides/average-bill.html

  const costElec = energyDiff * elecBalance * elecCostKwh
  const costGas = energyDiff * gasBalance * gasCostKwh
  const costTotal = costElec + costGas

  return {
    kettle: (
      <div className="stat-text">
        <p className="value">{format(cupsOfTea, 0)}</p>
        <p>cups of tea</p>
      </div>
    ),
    lightbulbs: (
      <div className="stat-text">
        <p>Run 100w lightbulb for</p>
        <p className="value">{format(yearsLightBulb, 0)}</p>
        <p>years</p>
      </div>
    ),
    car: (
      <div className="stat-text">
        <p>Drive an electric car</p>
        <p className="value">{format(miles, 0)}</p>
        <p>miles</p>
      </div>
    ),
    trees: (
      <div className="stat-text">
        <p>The amount</p>
        <p className="value">{format(trees, 0)}</p>
        <p>trees absorb in a year</p>
      </div>
    ),
    balloons: (
      <div className="stat-text">
        <p>Enough to fill</p>
        <p className="value">{format(balloons, 0)}</p>
        <p>ballons</p>
      </div>
    ),
    bricks: (
      <div className="stat-text">
        <p>Weighing in the same as</p>
        <p className="value">{format(bricks, 0)}</p>
        <p>bricks</p>
      </div>
    ),
    coins: (
      <span>
        You could save up to <b>£{format(costTotal, 2)}</b> per year
      </span>
    )
  }
}
