import React, { Fragment, useState } from "react"

import EPCGauge from "./EPCGauge"
import EPCCompare from "./EPCCompare"
import EPCCompareButton from "./EPCCompareButton"
import { ReactComponent as Cross } from "../icons/times-light.svg"

function EPCModal({ epc, plotType, onClose }) {
  const [showEpcCompare, setShowEpcCompare] = useState(false)
  const [userHome, setUserHome] = useState(null)

  return (
    <Fragment>
      <div
        className={`epc-modal
        ${showEpcCompare ? "epc-modal--wide" : ""}
        `}
      >
        <EPCGauge epc={epc} plotType={plotType} userHome={userHome} setShowEpcCompare={setShowEpcCompare} />

        {!showEpcCompare && (
          <EPCCompareButton onClick={() => setShowEpcCompare(true)} />
        )}

        {showEpcCompare && (
          <EPCCompare
            epc={epc}
            plotType={plotType}
            userHome={userHome}
            setUserHome={setUserHome}
            setShowEpcCompare={setShowEpcCompare}
          />
        )}
        <div className="epc-modal__close-button" onClick={onClose}>
          <Cross />
        </div>
      </div>

      <div className="epc-modal__mask" onClick={onClose}></div>
    </Fragment>
  )
}

export default EPCModal
