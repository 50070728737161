import React from "react"

import { ReactComponent as Bolt } from "../icons/bolt.svg"
import { ReactComponent as Info } from "../icons/info.svg"
import { ReactComponent as Leaf } from "../icons/leaf.svg"

function EPCBadge({ type, size = "md" }) {
  return (
    <div
      className={`epc-badge
    ${type === "Eco Now" ? "epc-badge--green" : "epc-badge--blue"}
    ${size === "lg" ? "epc-badge--large" : ""}
    `}
    >
      {type === "Eco Now" ? (
        <Leaf />
      ) : type === "Eco Electric" ? (
        <Bolt />
      ) : null}
      {type}
      {size === "md" && <Info />}
    </div>
  )
}

export default EPCBadge
