import React, { useState, useEffect, useContext } from "react"
import Page from "../components/Page"
import Axios from "axios"
import StateContext from "../StateContext"
import Loading from "../components/Loading"
import Controls from "../components/Controls"
import FsLightbox from "fslightbox-react"

import { ReactComponent as PlayIcon } from "../icons/play-icon.svg"

function Gallery() {
  const appState = useContext(StateContext)

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    })
  }

  const imagesArray = []
  const captionsArray = []
  const videoPostersArray = []

  return (
    <Page title="Gallery" container={true} fluid={true}>
      <h2 className="page-title">Gallery</h2>

      <div className="masonry-with-columns">
        {appState.galleryData
          ? appState.galleryData.map((item, index) => {
              captionsArray.push(item.name)
              imagesArray.push(item.fileUrl)

              if (item.fileUrl.endsWith("jpg")) {
                videoPostersArray.push(null)
                return (
                  <div key={index} className="image-thumb">
                    <div className="caption">{item.name}</div>
                    <img
                      src={item.fileUrl}
                      alt={item.name}
                      onClick={() => openLightboxOnSlide(index + 1)}
                    />
                  </div>
                )
              } else if (item.fileUrl.endsWith("mp4")) {
                videoPostersArray.push(
                  "https://generative-placeholders.glitch.me/image?width=600&height=300&img=20201029121356"
                )
                console.log(imagesArray)
                return (
                  <div
                    key={index}
                    className="video-thumb"
                    onClick={() => openLightboxOnSlide(index + 1)}
                  >
                    <PlayIcon />
                    <p className="title">{item.name}</p>
                  </div>
                )
              } else {
                return null
              }
            })
          : null // <Loading />
        }
      </div>

      {appState.galleryData ? (
        <>
          <FsLightbox
            toggler={lightboxController.toggler}
            sources={imagesArray}
            slide={lightboxController.slide}
            captions={captionsArray}
            // UIFadeOutTime={}
            videosPosters={videoPostersArray}
          />
          <Controls />
        </>
      ) : null}
    </Page>
  )
}

export default Gallery
