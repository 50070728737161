import React from "react"

import { ReactComponent as HomeIcon } from "../icons/home-switch-outline.svg"

function IndexButton(props) {
  const { goBack } = props;

  return (
    <>
      <a className="index-button" href="https://developments.redrowapp.co.uk/">
        <HomeIcon />
      </a>
    </>
  )
}

export default IndexButton
