import React from "react"

import { ReactComponent as PriceIcon } from "../icons/prices-icon.svg"
import { ReactComponent as HomesIcon } from "../icons/home-icon.svg"
import { ReactComponent as BedsIcon } from "../icons/beds-icon.svg"
import { ReactComponent as PhaseIcon } from "../icons/local-area-icon.svg"

function FilterIcon(props) {
  
  if (props.name) {
    const name = props.name.toLowerCase()
    switch(name) {
      case "price":
        return <PriceIcon />

      case "bedrooms":
        return <BedsIcon />
      
      case "housetypes":
        return <HomesIcon />

      case "phase":
        return <PhaseIcon />
      
      default:
        return <HomesIcon />
    }
  }
}

export default FilterIcon
