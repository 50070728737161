import React from "react"

function KeyboardKey({
  character,
  onTouch,
  special = false,
  light = false,
  disabled = false
}) {
  return (
    <div
      className={`keyboard-key
      ${special ? "keyboard-key--special" : ""}
      ${light ? "keyboard-key--light" : ""}
      ${disabled ? "keyboard-key--disabled" : ""}
      `}
      onClick={!disabled ? onTouch : null}
    >
      {character}
    </div>
  )
}

export default KeyboardKey
