import React from "react"

import { ReactComponent as RightChevron } from "../icons/chevron-right-solid.svg"

function EPCCompareButton({ onClick, type = 'tall' }) {
  return (
    <div onClick={onClick} className={`compare-button compare-button--${type}`}>
      <p className="compare-button__text">Compare Your Home</p>
      <div className="compare-button__icon">
        <RightChevron />
      </div>
    </div>
  )
}

export default EPCCompareButton
