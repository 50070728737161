import React, { useContext } from "react"
import { Link } from "react-router-dom"
import StateContext from "../StateContext"

function Header() {
  const appState = useContext(StateContext)

  return (
    <>
      <div className="site-header">
        <h1>
          <Link to="/">
            <span className="light">Discover</span> {appState.devName}
          </Link>
        </h1>

        {/* {appState.devStyle ? (
        <Link to="/">
          <img
            src={appState.devStyle.images.tabletLogoUrl}
            alt="{appState.devName}"
            className="img-fluid"
            width="200"
          />
        </Link>
      ) : (
        ""
      )} */}
      </div>
    </>
  )
}

export default Header
