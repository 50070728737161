import React, { useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"
import PlotCard from "../components/PlotCard"
import FilterStatusBar from "../components/FilterStatusBar"
import Controls from "../components/Controls"
import { useMediaQuery } from "react-responsive"

//helpers
import {
  getStatusID
} from "../components/Helpers"

function Plots() {
  //app state
  const appState = useContext(StateContext)

  const handleMediaQueryChange = (matches) => {
    // switch to the grid view if not desktop
    if (!matches) {
      // setViewState("grid")
    }
  }
  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 992 },
    undefined,
    handleMediaQueryChange
  )

  return (
    <Page title="Our Homes" container={true} fluid={false} spaceBelow>
      <h2 className="page-title">
        Find<span className="bold"> Your New Home</span>
      </h2>

      <div className="top-bar row">
        <div className="col-md-12">
          <FilterStatusBar />
        </div>
      </div>

      <div className="plots-list">
        <div className="plots-grid row" key="plots-grid">
          {appState.plotsData.length >= 1 ? (
            [...appState.plotsData].sort((a,b) => parseFloat(a.plotNumber) - parseFloat(b.plotNumber)).map((item) => {
              if (item.plotStatusId !== getStatusID(appState.plotStatuses, "Hidden")) {
                return <PlotCard item={item} key={item.id} />
              }
            })
          ) : (
            <div className="col-md-12 plot-card item" key={0}>
              <Card className="mb-4">
                <Card.Body>No available plots.</Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>
      <Controls />
    </Page>
  )
}

export default Plots