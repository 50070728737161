import React, { useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import { Link } from "react-router-dom"
import PulseButton from "../components/PulseButton"
import Controls from "../components/Controls"

function Home() {
  const appState = useContext(StateContext)
  return (
    <Page title="Home" container={false}>
      {appState.devStyle.images.wallIdleVideoUrl ? (
        <video
          className="header-video"
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source
            src={appState.devStyle.images.wallIdleVideoUrl}
            type="video/mp4"
          />
        </video>
      ) : null}

      <div className="home-content-wrap">
        <div className="home-content text-center">
          <PulseButton />

          <h2 className="page-title welcome">Find your new home</h2>
        </div>
      </div>
      <Controls />
    </Page>
  )
}

export default Home
