import React from "react"
import { priceFormatter } from "./Helpers"

import { ReactComponent as BedsIcon } from "../icons/beds-icon.svg"
import { ReactComponent as PricesIcon } from "../icons/prices-icon.svg"

function PlotDetails(props) {
  const { item, status } = props
  return (
    <div className="plot-details">
      {item.plotType.numberOfBeds ? (
        <div className="item-detail beds">
          <BedsIcon /> {item.plotType.numberOfBeds} bedroom home
        </div>
      ) : null}

      {status.name === "Available" ? (
        <div className="item-detail price">
          <PricesIcon /> {priceFormatter(item.price)}
        </div>
      ) : null}

      {item.plotType.imperialArea ? (
        <div className="item-detail area">
          Total Area: {item.plotType.imperialArea} sq ft
        </div>
      ) : null}
    </div>
  )
}

export default PlotDetails
