import React, { useEffect, useState, useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import "nouislider/distribute/nouislider.css"
import LoginForm from "../components/LoginForm"
import PlotCard from "../components/PlotCard"
import Controls from "../components/Controls"

function Favourite() {
  //app state
  const appState = useContext(StateContext)

  const [favHomes, setFavHomes] = useState([])

  useEffect(() => {
    //filter out only show fav homes.
    if (appState.plotsData && appState.favPlots) {
      let favHomes = appState.plotsData.filter((el) => {
        if (appState.favPlots.includes(el.id)) {
          return el
        }
        return null
      })
      setFavHomes(favHomes)
    }
  }, [appState.favPlots, appState.plotsData])

  return (
    <Page title="Your Favourites" container={true} fluid={false}>
      <h2 className="page-title">Your Favourites</h2>

      <div className="plots-list">
        <TransitionGroup className="plots-list row">
          {favHomes.length >= 1 ? (
            favHomes.map((item) => {
              return (
                <CSSTransition key={item.id} timeout={200} classNames="item">
                  <PlotCard item={item} />
                </CSSTransition>
              )
            })
          ) : (
            <CSSTransition key={0} timeout={200} classNames="item">
              <div className="col-md-12 plot-card item" key={0}>
                <Card className="mb-4 shadow-sm">
                  <Card.Body>
                    Looks like you don't have any favourites.
                  </Card.Body>
                </Card>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>

      <LoginForm />

      <Controls showRefine />
    </Page>
  )
}

export default Favourite
