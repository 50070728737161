import React, { useContext } from "react";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import { useHistory } from "react-router-dom";

function PlotMarker(props) {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const history = useHistory();
  const goToRoute = (location) => history.push(location);

  const { posY, posX, posZ, colour, plotId, plotNum, isNotMobile, scale } =
    props;

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const markerSize = clamp(1.5 / scale, 0.25, 1.0);

  const divStyle = {
    top: `calc(${posY}% - 10px)`,
    left: `calc(${posX}% - 10px)`,
    zIndex: posZ,
    transform: `scale(${markerSize})`,
    backgroundColor: colour,
  };

  function PlotMarkerClicked() {
    if (!appState.isPanning) {
      if (isNotMobile) {
        appDispatch({
          type: "showPlotPreview",
          data: { plotid: plotId, markerId: plotNum },
        });
      } else {
        goToRoute(`/plots/${plotId}`);
      }
    }
  }

  return (
    <div
      key={plotNum}
      id={plotNum}
      className="plotMarker"
      onClick={PlotMarkerClicked}
      style={divStyle}
    >
      {plotNum}
    </div>
  );
}

export default PlotMarker;
