import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import Page from "../components/Page";
import "../styles/Siteplan.scss";
import PlotMarkerMP from "../components/PlotMarkerMP";
import Controls from "../components/Controls";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ItemMenu from "../components/ItemMenu";
import { useHistory } from "react-router-dom";
import { ReactComponent as ZoomControlPlus } from "../icons/zoom-control-plus.svg";
import { ReactComponent as ZoomControlMinus } from "../icons/zoom-control-minus.svg";
import { ReactComponent as IconCenter } from "../icons/zoom-control-center.svg";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import PlotPreview from "../components/PlotPreview";
import StatusKey from "../components/StatusKey";

function Siteplan() {
  const imageRes = 900;
  const history = useHistory();
  const goToRoute = (location) => history.push(location);

  const [scale, setScale] = useState(1);
  const [disablePan, setDisablePan] = useState(true);

  const transformComponentRef = useRef(null);
  const motionFrameRef = useRef(null);

  const [currentItem, setCurrentItem] = useState(false);

  const [multipleItems, setMultipleItems] = useState([]);

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const { id } = useParams();
  const [imageUrl, setImageUrl] = useState("");
  const [currentId, setCurrentId] = useState(id);
  const imageRef = useRef(null);

  const [plotMarkers, setPlotMarkers] = useState([]);

  let interactiveTimer;

  const scaleValue = imageRes / imageRef?.current?.naturalWidth;
  const calcImageHeight = scaleValue * imageRef?.current?.naturalHeight;
  const difference = imageRes - calcImageHeight;
  const topMargin = difference / 2;

  const calcImageWidth = scaleValue * imageRef?.current?.naturalWidth;

  const isDesktopNotMobile = useMediaQuery({ minWidth: 992 }, undefined);

  useEffect(() => {
    getPlotContainerDataForId(appState.plotContainerData, id);
  }, [appState.filteredHomes, id, scaleValue, appState.plotContainerData]);

  const getAllPhases = () => {
    for (const thisArrayItem of appState.plotContainerData) {
      setMultipleItems((prevArray) => [
        ...prevArray,
        { slug: `${thisArrayItem.id}`, title: `${thisArrayItem.name}` },
      ]);
    }
  };

  useEffect(() => {
    if (!appState.plotContainerData) return;
    getAllPhases();
  }, [appState.plotContainerData]);

  function getPlotContainerDataForId(obj, targetId) {
    if (!obj) return;

    obj.forEach((thisData) => {
      if (parseInt(targetId) === 0) {
        targetId = thisData.id;
      }

      setCurrentId(targetId);

      if (thisData.id === parseInt(targetId)) {
        setImageUrl(thisData.interactiveAssetURI);
        addMarkers(thisData.interactiveRegions);

        return false;
      }
      if (Object.prototype.hasOwnProperty.call(thisData, "plotContainers")) {
        getPlotContainerDataForId(thisData.plotContainers, targetId);
      }
    });
  }

  const getCurrentItem = () => {
    if (id === "0") {
      setCurrentItem(multipleItems[0]);
    } else {
      multipleItems.every(function (thisItem) {
        if (thisItem.slug === id) {
          setCurrentItem(thisItem);
          return false;
        }
        return true;
      });
    }
  };

  useEffect(() => {
    if (!multipleItems) return;
    getCurrentItem();
  }, [multipleItems]);

  function addMarkers(interactiveRegionsArray) {
    setPlotMarkers([]);

    interactiveRegionsArray.forEach((interactiveRegion) => {
      if (!interactiveRegion.isTargetAContainer) {
        //show filtered plot markers
        const thisPlotInfo = appState.filteredHomes.find(
          (plotItem) => plotItem.id == interactiveRegion.targetId
        );

        if (thisPlotInfo) {
          const thisPlotStatus = appState.plotStatuses.find(
            (statusItem) => statusItem.id == thisPlotInfo.plotStatusId
          );

          const coordY = calcImageHeight * interactiveRegion.startY;
          const newPosY = topMargin + coordY;
          const newPosX = calcImageWidth * interactiveRegion.startX;

          const markerWidth = calcImageWidth * interactiveRegion.width;
          const markerHeight = calcImageHeight * interactiveRegion.height;

          const data = {
            x: newPosX,
            y: newPosY,
            width: markerWidth,
            height: markerHeight,
            id: interactiveRegion.targetId,
            plotNumber: thisPlotInfo.plotNumber,
            plotStatus: thisPlotStatus.name,
            colour: thisPlotStatus.color,
          };
          setPlotMarkers((prevArray) => [...prevArray, data]);
        }
      }
    });
  }

  function StartInteractiveTimeout() {
    clearInterval(interactiveTimer);
    interactiveTimer = setInterval(function () {
      EnableMarkerInteraction();
    }, 200);
  }

  function EnableMarkerInteraction() {
    clearInterval(interactiveTimer);
    appDispatch({ type: "isPanning", data: false });
  }

  useEffect(() => {
    if (appState.isPreviewPanelOpen) {
      setDisablePan(false);
    } else {
      setDisablePan(true);
    }
  }, [appState.isPreviewPanelOpen]);

  useEffect(() => {
    if (scale > 1) {
      setDisablePan(false);
    } else {
      if (appState.isPreviewPanelOpen) {
        appDispatch({ type: "closePlotPreview" });
      }

      setDisablePan(true);
    }
  }, [scale]);

  function ZoomOut() {
    if (scale > 1) {
      const factor = Math.log((scale - 1.0) / scale);
      transformComponentRef.current.zoomOut(-factor, 300);
      setScale((scale) => scale - 1);
    }
  }

  function ZoomIn() {
    if (scale < 4) {
      const factor = Math.log((scale + 1.0) / scale);
      transformComponentRef.current.zoomIn(factor, 300);
      setScale((scale) => scale + 1);
    }
  }

  function CenterView() {
    if (transformComponentRef.current != null) {
      const { centerView } = transformComponentRef.current;
      centerView(1, 1000, "easeOut");
      setScale(1);
    }
  }

  function PlotMarkerClicked(thisId, thisPlotNum) {
    if (!appState.isPanning) {
      if (isDesktopNotMobile) {
        appDispatch({
          type: "showPlotPreview",
          data: { plotid: thisId, markerId: thisPlotNum },
        });
      } else {
        goToRoute(`/plots/${thisId}`);
      }
    }
  }

  useEffect(() => {
    zoomToPlotMarker();
  }, [appState.selectedPlotMarkerId]);

  useEffect(() => {
    if (!appState.isPreviewPanelOpen) CenterView();
  }, [appState.isPreviewPanelOpen]);

  function zoomToPlotMarker() {
    if (appState.selectedPlotMarkerId) {
      let thisMarker = document.getElementById(appState.selectedPlotMarkerId);
      if (transformComponentRef.current != null) {
        const { zoomToElement } = transformComponentRef.current;
        let zoomToScale = 2;
        if (scale > 2) zoomToScale = scale;

        if (appState.plotPreviewContent != null) {
          zoomToElement(thisMarker, zoomToScale, 1500, "easeOut");
        }
      }
    }
  }

  return (
    <Page title="Site Plan" container={false}>
      <motion.div
        ref={motionFrameRef}
        className="MainWrapper"
        key={"MainWrapper"}
        animate={{
          width: appState.isPreviewPanelOpen ? 70 + "vw" : 100 + "vw",
        }}
        onAnimationComplete={
          appState.isPreviewPanelOpen ? zoomToPlotMarker : CenterView
        }
        transition={{
          x: { type: "spring", stiffness: 300, damping: 40 },
        }}
      >
        <StatusKey />
        <TransformWrapper
          ref={transformComponentRef}
          initialScale={scale}
          maxScale={4}
          limitToBounds={true}
          onZoomStop={(e) => {
            setScale(e.state.scale);
          }}
          panning={{ disabled: disablePan }}
          onPanning={(e) => {
            appDispatch({ type: "isPanning", data: true });
          }}
          onPanningStop={(e) => {
            StartInteractiveTimeout();
          }}
          centerOnInit
          onZoom={(e) => {
            setScale(e.state.scale);
          }}
        >
          {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
            <>
              {!appState.isPreviewPanelOpen ? (
                <div className="zoomControlPanelSiteplan">
                  <button className="zoomInButton" onClick={() => ZoomIn()}>
                    <ZoomControlPlus />
                  </button>
                  <button className="zoomOutButton" onClick={() => ZoomOut()}>
                    <ZoomControlMinus />
                  </button>
                  <button
                    className="centerSpinButton"
                    onClick={() => CenterView()}
                  >
                    <IconCenter />
                  </button>
                </div>
              ) : null}

              <TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                }}
                className="transform-component"
              >
                <div
                  className="zoomContainerSiteplan"
                  style={{ width: `${imageRes}px` }}
                >
                  <img
                    ref={imageRef}
                    style={{ width: `${imageRes}px`, height: `${imageRes}px` }}
                    src={imageUrl}
                    alt={imageUrl}
                    onLoad={() =>
                      getPlotContainerDataForId(appState.plotContainerData, id)
                    }
                  />
                  {plotMarkers.map((plotMarker) => (
                    <div key={plotMarker.id.toString()}>
                      <PlotMarkerMP
                        posX={plotMarker.x}
                        posY={plotMarker.y}
                        markerWidth={plotMarker.width}
                        markerHeight={plotMarker.height}
                        plotId={plotMarker.id}
                        plotNum={plotMarker.plotNumber}
                        plotColour={plotMarker.colour}
                        myFunction={() =>
                          PlotMarkerClicked(
                            plotMarker.id,
                            plotMarker.plotNumber
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </motion.div>
      <div className="sub-menu">
        <div className="container">
          {multipleItems &&
          multipleItems.length > 1 &&
          !appState.isPreviewPanelOpen ? (
            currentItem ? (
              <ItemMenu
                items={multipleItems}
                currentItem={`${currentId}`}
                route="siteplan"
                label="Siteplans"
              />
            ) : null
          ) : null}
        </div>
      </div>
      {isDesktopNotMobile ? <PlotPreview layout="one-col" /> : null}
      {!appState.isPreviewPanelOpen ? (
        <Controls showRefine hideBackButton />
      ) : (
        <Controls hideRefine hideBackButton />
      )}
    </Page>
  );
}

export default Siteplan;
