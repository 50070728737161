import React, { useContext, useState, useEffect } from "react"
import Page from "../components/Page"
import { Link } from "react-router-dom"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"
import Badge from "react-bootstrap/Badge"
import Loading from "../components/Loading"
import {
  priceFormatter,
  dynamicSort,
  stringToSlug,
  getStatusID,
  getStatusName
} from "../components/Helpers"
import FsLightbox from "fslightbox-react"

import FilterStatusBar from "../components/FilterStatusBar"
import Controls from "../components/Controls"

import { ReactComponent as BedsIcon } from "../icons/beds-icon.svg"
import { ReactComponent as PricesIcon } from "../icons/prices-icon.svg"

import { ReactComponent as VirtualTourIcon } from "../icons/virtual-tour-icon.svg"
import { ReactComponent as GalleryIcon } from "../icons/gallery-icon.svg"
import { ReactComponent as FloorplanIcon } from "../icons/floorplans-icon.svg"

function Types({ showAllTypes = false }) {
  //app state
  const appState = useContext(StateContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showAllTypes])

  const [lightboxController, setLightboxController] = useState({
    toggler: true,
    index: 0,
    openOnMount: false
  })

  const [haveFloorplans, setHaveFloorplans] = useState(false)
  const [gallerySources, setGallerySources] = useState([])
  const [galleryCaptions, setGalleryCaptions] = useState([])

  function updateGallery(index) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      index: index,
      openOnMount: true
    })
  }

  // get the floorplans images
  useEffect(() => {
    const gallerySources = []
    const galleryCaptions = []

    appState.typesData.map((type) => {
      let typeSources = []
      let typeCaptions = []

      //sort images
      let sortedImages = type.assets.slice().sort(dynamicSort("order"))

      sortedImages.map((item) => {
        if (item.name.startsWith("FP")) {
          setHaveFloorplans(true)

          //add image
          typeSources.push(item.fileUrl)

          //add caption
          if (item.name.endsWith("0")) {
            typeCaptions.push(`${type.name} - Ground Floor`)
          } else if (item.name.endsWith("1")) {
            typeCaptions.push(`${type.name} - First Floor`)
          } else if (item.name.endsWith("2")) {
            typeCaptions.push(`${type.name} - Second Floor`)
          } else if (item.name.endsWith("3")) {
            typeCaptions.push(`${type.name} - Third Floor`)
          } else if (item.name.endsWith("4")) {
            typeCaptions.push(`${type.name} - Fourth Floor`)
          } else {
            typeCaptions.push("")
          }
        }
        return null
      })

      gallerySources.push(typeSources)
      galleryCaptions.push(typeCaptions)

      return null
    })
    setGallerySources(gallerySources)
    setGalleryCaptions(galleryCaptions)
  }, [appState.typesData])

  function availableCount(array) {
    var count = 0
    array.map((i) => {
      if (i.plotStatusId === getStatusID(appState.plotStatuses, "Available")) {
        count++
      }
      return null
    })
    return count
  }

  function plotFilteredCheck(plotID) {
    let plotInFiltered = appState.filteredHomes.find((el) => el.id === plotID)
    return plotInFiltered ? true : false
  }

  function filteredCount(array) {
    var count = 0
    array.map((i) => {
      if (plotFilteredCheck(i.id)) {
        count++
      }
      return null
    })
    return count
  }

  function getFilteredPlots(plots) {
    const filteredPlots = [];
    plots.forEach((plot) => {
      if(plotFilteredCheck(plot.id)) {
        filteredPlots.push(plot);
      }
    })
    return filteredPlots;
  }

  return (
    <Page title="Our Homes" fluid={false} container={true} spaceBelow>
      <div className="types-header">
        <h2 className="page-title">Our House Types</h2><div>
        </div>
      </div>

      <div className="top-bar row">
        <div className="col-md-12">
          <FilterStatusBar />
        </div>
      </div>

      <div className="row">
        {appState.filteredHomes.length >= 1 || showAllTypes ? (
          appState.typesData ? (
            appState.typesData.map((item, key) => {
              let sortedImages = item.assets.slice().sort(dynamicSort("order"))
              let img = sortedImages[0]

              let plotStatuses = appState.plotStatuses

              //get avail colour
              let availableStatus = plotStatuses.find(
                (el) => el.name === "Available"
              )

              //get sold colour
              let soldStatus = plotStatuses.find((el) => el.name === "Sold")

              //get current type virtual tour
              const typeTours = appState.devMenuItems
                ? appState.devMenuItems.typeTours
                : ""
              const typeSlug = stringToSlug(item.name)
              // const currentTour = typeTours[typeSlug]
              let currentTour = null
              if (typeTours) {
                currentTour = typeTours.find((el) => el.slug === typeSlug)
              }

              return (
                <>
                  {filteredCount(item.plots) ? (
                    <div key={item.id} className="col-md-12 col-xl-4">
                      <Card className="mb-4 type-card">
                        <div className="row">
                          <div className="col-md-7 col-xl-12">
                            <div key={img.assetId}>
                              <Card.Img src={img.fileUrl} />
                              <Card.ImgOverlay>
                                <div className="img-overlay-content">
                                  <div className="overlay-badge">
                                    {availableCount(item.plots) ? (
                                      <Badge
                                        pill
                                        variant="light"
                                        style={{
                                          color: availableStatus.color
                                        }}
                                      >
                                        <span>
                                          {filteredCount(item.plots)}{" "}
                                          Available
                                        </span>
                                      </Badge>
                                    ) : (
                                      <Badge
                                        pill
                                        variant="light"
                                        style={{ color: soldStatus.color }}
                                      >
                                        Not Available
                                      </Badge>
                                    )}
                                  </div>
                                </div>
                              </Card.ImgOverlay>
                            </div>
                          </div>
                          <div className="col-md-5 col-xl-12">
                            <Card.Body>
                              <Card.Title>{item.name}</Card.Title>

                              <div className="type-details">
                                <div className="item">
                                  <BedsIcon /> {item.numberOfBeds} bedroom home
                                </div>
                                {availableCount(item.plots) >= 1 ? (
                                  <div className="item">
                                    <PricesIcon /> Priced from{" "}
                                    {priceFormatter(item.fromPrice)}
                                  </div>
                                ) : null}
                              </div>

                              <div className="plot-list">
                                {getFilteredPlots(item.plots).sort((a,b) => parseFloat(a.plotNumber) - parseFloat(b.plotNumber)).map((plot) => {
                                  return (
                                    <div key={plot.id}>
                                      <Link
                                        className="plot-item"
                                        to={`/plots/${plot.id}`}
                                      >
                                        <span>
                                          <strong>
                                            Plot {plot.plotNumber}
                                          </strong>{" "}
                                          {(plot.plotStatusId === getStatusID(appState.plotStatuses, "Available")) ? priceFormatter(plot.price) : getStatusName(appState.plotStatuses, plot.plotStatusId)}
                                        </span>
                                        <span className="view">
                                          View Home &gt;
                                        </span>
                                      </Link>
                                    </div>
                                  )
                                })}
                              </div>

                              <div className="type-menu">
                                {currentTour ? (
                                  <div className="item">
                                    <Link to={`interior-tour/${typeSlug}`}>
                                      <VirtualTourIcon />
                                      Virtual Tour
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="item">
                                    <span className="disabled">
                                      <VirtualTourIcon />
                                      Virtual Tour
                                    </span>
                                  </div>
                                )}

                                {gallerySources[key] &&
                                gallerySources[key].length > 0 ? (
                                  <div className="item">
                                    <button onClick={() => updateGallery(key)}>
                                      <FloorplanIcon />
                                      Floorplans
                                    </button>
                                  </div>
                                ) : null}

                                <div className="item">
                                  <Link to="/gallery">
                                    <GalleryIcon />
                                    Gallery
                                  </Link>
                                </div>
                                {/* <div className="item">
                                  <Link to="/siteplan">
                                    <SitePlanIcon />
                                    Find on Site Plan
                                  </Link>
                                </div> */}
                              </div>
                            </Card.Body>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ) : null}
                </>
              )
            })
          ) : (
            <Loading />
          )
        ) : (
          <div className="col-md-12 plot-card item" key={0}>
            <Card className="mb-4">
              <Card.Body>No types matched your criteria.</Card.Body>
            </Card>
          </div>
        )}
      </div>

      {haveFloorplans && gallerySources.length > 0 ? (
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={gallerySources[lightboxController.index]}
          captions={galleryCaptions[lightboxController.index]}
          key={lightboxController.index}
          openOnMount={lightboxController.openOnMount}
        />
      ) : null}
      <Controls showRefine={!showAllTypes} />
    </Page>
  )
}

export default Types
