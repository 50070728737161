import React from "react";

function PlotMarkerMP(props) {
  const {
    posX,
    posY,
    markerWidth,
    markerHeight,
    plotColour,
    myFunction,
    plotNum,
  } = props;

  let divStyle = {
    top: parseInt(posY - markerHeight / 2) + "px",
    left: parseInt(posX - markerHeight / 2) + "px",
    width: parseInt(markerWidth * 2) + "px",
    height: parseInt(markerWidth * 2) + "px",
    backgroundColor: plotColour,
  };
  divStyle.transform = "scale(0.5)";

  const textStyle = {
    fontSize:
      plotNum.length > 3
        ? parseInt(markerWidth * 0.5) + "px"
        : parseInt(markerWidth * 0.8) + "px",

    paddingTop: "0",
  };

  return (
    <>
      <div
        key={plotNum}
        id={plotNum}
        className="plot-marker-mp"
        onClick={myFunction}
        style={divStyle}
      >
        <div className="plot-marker-mp__text" style={textStyle}>
          {plotNum}
        </div>
      </div>
    </>
  );
}

export default PlotMarkerMP;
